<template>
  <section class="container">
    <EstoreCard />
    <!-- 検索欄 -->
    <div class="input-area">
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-combobox
          ref="combobox"
          v-model="searchQuery"
          :items="AutocompleteItems"
          :label="$t('message.search')"
          clearable
          solo-inverted
          autofocus
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="removeFocus"
        />

        <v-spacer />
      </v-toolbar>
    </div>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <!-- 家具種別 -->
        <v-card class="mt-3">
          <v-card-title>
            <span class="text-h4 ">{{ $t('message.furniture_type_selection') }}</span>
          </v-card-title>
          <v-treeview
            v-model="selected"
            selectable
            :open.sync="opened"
            dense
            :items="formattedGroupsWithCounts"
            activatable
            item-key="name"
            open-all
            class="custom-treeview"
            @input="updateAndFilterItems"
          >
            <template v-slot:label="{ item }">
              <div
                class="full-width-label"
                @click="toggleCheckbox(item)"
              >
                {{ item.name }}
              </div>
            </template>
          </v-treeview>
        </v-card>
      </v-col>

      <!-- 詳細種別 -->
      <v-col
        cols="12"
        md="6"
      >
        <v-card class="mt-3">
          <v-card-title>
            <span class="text-h4 ">{{ $t('message.detail_type') }}</span>
          </v-card-title>
          <v-treeview
            v-model="detail_type_selected"
            selectable
            :open.sync="detailOpened"
            dense
            :items="formattedDetailTypeGroups"
            activatable
            item-key="name"
            open-all
            class="custom-treeview"
            @input="updateAndFilterItems"
          >
            <template v-slot:label="{ item }">
              <div
                class="full-width-label"
                @click="detailToggleCheckbox(item)"
              >
                {{ item.name }}
              </div>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
    </v-row>

    <!-- フィルター -->
    <!-- <v-card class="mt-3 mb-5">
      <v-card-title>
        <span class="text-h4 ">{{ $t('message.attribute') }}</span>
      </v-card-title>

      <v-row>
        <v-col
          v-for="(filter_type, index) in filterArray"
          :key="index"
          cols="4"
          sm="3"
          md="2"
          lg="2"
          class="text-container"
        >
          <v-checkbox
            :key="index"
            v-model="selectedFilters[filter_type]"
            :label="filter_type"
            class="text-no-wrap"
            @change="updateAndFilterItems"
          />
        </v-col>
      </v-row>
    </v-card> -->

    <v-alert
      dense
      text
      type="success"
    >
      データ件数: {{ filteredItemsCount }}
    </v-alert>

    <div
      class="d-flex justify-center align-center"
    >
      <div
        v-if="showMasonry"
        :id="masonryContainer"
        v-masonry
        transition-duration="0s"
        item-selector=".item"
        class="masonryWrap"
        :origin-top="true"
        :horizontal-order="false"
      >
        <div
          v-for="item in Items"
          :key="item.name"
          v-masonry-tile
          class="item col-4 col-md-2"
        >
          <furnishing-display :item="item" />
        </div>
      </div>
    </div>

    <div
      v-intersect="onIntersect"
    />
  </section>
</template>

<script>
// ライブラリのインポート
  import axios from 'axios'
  import FurnishingDisplay from '../components/FurnishingDisplay.vue'

  export default {
    name: 'Furnishings',
    metaInfo: {
      title: 'Furnishings',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden,画像加工ツール',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FF14ハウジングの家具データベースです。家具を選択し、数量を入力することで家具リストを生成することができます。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      FurnishingDisplay,
    },
    props: {},
    data () {
      return {
        furnishingsImages: [], // furnishings_img.jsonからのデータ
        furniturePictures: [], // furniture_pictures.jsonからのデータ
        numberOfFurnitureTypes: [],
        attribute: [
          {
            name: '属性',
            children: [
              { name: '染色' },
              { name: 'EX' },
              { name: '店売り' },
            ],
          },
        ],
        showMasonry: true,
        AutocompleteItems: [],
        start: 0,
        end: 0,
        isLoading: false,
        masonryContainer: 0,
        selected: [],
        detail_type_selected: [],
        opened: [],
        detailOpened: [],
        groups: [],
        detailGroups: [],
        currentPage: 1,
        perPage: 30,
        allItems: [],
        allItemsTmp: [],
        searchQuery: '',
        searchWord: '',
        search: '',
        addedItems: [], // 追加されたアイテムを保持するための新しい配列
        Items: [],
        rawItems: [],
        sortDesc: true,
        filterArray: ['染色'],
        selectedFilters: {}, // 選択されたフィルターの状態を保持するオブジェクト
        options: {
          cssMaxWidth: document.documentElement.clientWidth - 30,
          cssMaxHeight: '700',
          selectionStyle: {
            cornerSize: 20,
          },
        },
      }
    },
    computed: {
      formattedGroupsWithCounts () {
        return this.formattedGroups.map(group => {
          // ここで各グループごとの件数を計算
          const count = this.calculateItemCount(group)
          return {
            ...group,
            name: `${group.name} (${count})`, // アイテム名の隣に件数を表示
          }
        })
      },
      filteredItemsCount () {
        // ここでフィルター適用後のアイテム数を計算
        // 例: this.items がアイテムの配列である場合
        return this.allItems.length
      },
      formattedDetailTypeGroups () {
        return this.detailGroups.map(group => ({
          name: group.name,
          children: group.types.map((type, index) => ({
            name: group.mapping[index],
          })),
        }))
      },
      formattedGroups () {
        return this.groups.map(group => ({
          name: group.name,
          children: group.types.map((type, index) => ({
            name: group.mapping[index],
          })),
        }))
      },
      isMobile () {
        // 600px以下の画面幅をモバイルとみなす
        return window.innerWidth <= 600
      },
    },
    watch: {
      // searchQueryが変更されたときに実行される関数
      searchQuery: function (newQuery) {
        this.updateAndFilterItems() // 3. loadMoreItemsの実行
      },
      search: function (newQuery) {
        console.log('this.newQuery')
        console.log(newQuery)
        console.log('this.search')
        console.log(this.search)
        // this.searchWord = newQuery
      },
    },
    // コンポーネントがマウントされた時にリストをロードする
    mounted () {
      // this.loadList()
    },
    created: function () {
      // フィルターオプションの初期化
      this.filterArray.forEach(filter => {
        this.selectedFilters[filter] = false
      })

      axios.get(`../../furnishings_img.json?timestamp=${new Date().getTime()}`).then(res => {
        this.furnishingsImages = res.data
      })

      axios.get(`../../furniturePictures/furniture_pictures.json?timestamp=${new Date().getTime()}`).then(res => {
        this.furniturePictures = res.data
      })

      switch (this.$i18n.locale) {
        case 'ja':
          axios.get(`../../furnishings_ja.json?timestamp=${new Date().getTime()}`).then(res => {
            this.numberOfFurnitureTypes = res.data
            this.allItems = res.data
            this.AutocompleteItems = this.allItems.map(item => item.name)
            this.rawItems = res.data
            this.filterArray = ['染色', 'EX', '店売り']
            this.detailGroups = [
              {
                name: 'プレビュー種別',
                types: ['プレビューなし', '椅子・ソファ', '机', '棚・チェスト', '旗・額縁・ポスター', 'ベッド', '照明', '料理', '時計', '植物', 'ぬいぐるみ・フィギュア', '風呂', '壁・柱・仕切り', '窓', '敷物', '機能家具', 'その他', '水場', '足場', '店舗'],
                mapping: ['プレビューなし', '椅子・ソファ', '机', '棚・チェスト', '旗・額縁・ポスター', 'ベッド', '照明', '料理', '時計', '植物', 'ぬいぐるみ・フィギュア', '風呂', '壁・柱・仕切り', '窓', '敷物', '機能家具', 'その他', '水場', '足場', '店舗'],
              },
              {
                name: '属性',
                types: ['染色', 'EX', '店売り', '課金家具', '補足', 'ゲーム内画像無し'],
                mapping: ['染色', 'EX', '店売り', '課金家具', '補足', 'ゲーム内画像無し'],
              },
              // {
              //   name: '属性',
              //   types: ['染色', 'EX', '店売り', '課金家具', 'カメラ透過防止', '座り判定', '居眠り判定', '補足', 'ゲーム内画像無し'],
              //   mapping: ['染色', 'EX', '店売り', '課金家具', 'カメラ透過防止', '座り判定', '居眠り判定', '補足', 'ゲーム内画像無し'],
              // },
              // {
              //   name: '光源',
              //   types: ['影(家具)', '影(PC)', '貫通', '暖色', '寒色', '中性色'],
              //   mapping: ['影(家具)', '影(PC)', '貫通', '暖色', '寒色', '中性色'],
              // },
            ]
            this.groups = [
              {
                name: '調度品(全体)',
                types: ['調度品', '台座', '卓上', '壁掛', '敷物'],
                mapping: ['調度品', '調度品(台座)', '調度品(卓上)', '調度品(壁掛)', '調度品(敷物)'],
              },
              {
                name: '庭具(全体)',
                types: ['庭具'],
                mapping: ['庭具'],
              },
              {
                name: '内装建材',
                types: ['内壁', '床', '天井照明'],
                mapping: ['内装建材(内壁)', '内装建材(床)', '内装建材(天井照明)'],
              },
              {
                name: '外装建材',
                types: ['屋根', '外壁', '窓', '扉', '屋根装飾', '外壁装飾', '看板', '塀'],
                mapping: ['外装建材(屋根)', '外装建材(外壁)', '外装建材(窓)', '外装建材(扉)', '外装建材(屋根装飾)', '外装建材(外壁装飾)', '外装建材(看板)', '外装建材(塀)'],
              },
            ]
            for (const item of this.allItems) {
              item.num = ''
            }
            this.loadMoreItems()
          })
          break
        case 'en':
          axios.get(`../../furnishings_en.json?timestamp=${new Date().getTime()}`).then(res => {
            this.numberOfFurnitureTypes = res.data
            this.allItems = res.data
            this.AutocompleteItems = this.allItems.map(item => item.name)
            this.rawItems = res.data
            this.filterArray = ['staining', 'untradable']
            this.detailGroups = [
              {
                name: 'Attribute',
                types: ['staining', 'untradable'],
                mapping: ['staining', 'untradable'],
              },
              // {
              // name: 'attribute',
              // types: ['Dyeing', 'EX', 'Sold in store', 'Paid furniture', 'Camera transmission prevention', 'Sitting detection', 'Dozing detection', 'Supplementary', 'No in-game image'] ,
              // mapping: ['Dyeing', 'EX', 'Sold in store', 'Paid furniture', 'Camera transmission prevention', 'Sitting detection', 'Dozing detection', 'Supplementary', 'No in-game image'] ,
              // },
              // {
              // name: 'light source',
              // types: ['Shadow (furniture)', 'Shadow (PC)', 'Penetration', 'Warm color', 'Cool color', 'Neutral color'],
              // mapping: ['Shadow (furniture)', 'Shadow (PC)', 'Penetration', 'Warm color', 'Cool color', 'Neutral color'],
              // },
            ]

            this.groups = [
              {
                name: 'Furnishing(all)',
                types: ['Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug'],
                mapping: ['Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug'],
              },
              {
                name: 'Outdoor Furnishing(all)',
                types: ['Outdoor Furnishing'],
                mapping: ['Outdoor Furnishing'],
              },
              {
                name: 'Interior',
                types: ['Interior Wall', 'Flooring', 'Ceiling Light'],
                mapping: ['Interior Wall', 'Flooring', 'Ceiling Light'],
              },
              {
                name: 'Exterior',
                types: ['Roof', 'Exterior Wall', 'Window', 'Door', 'Roof Decoration', 'Exterior Wall Decoration', 'Placard', 'Fence'],
                mapping: ['Roof', 'Exterior Wall', 'Window', 'Door', 'Roof Decoration', 'Exterior Wall Decoration', 'Placard', 'Fence'],
              },
            ]
            for (const item of this.allItems) {
              item.num = ''
            }
            this.loadMoreItems()
          })
          break
        default:
          axios.get(`../../furnishings_en.json?timestamp=${new Date().getTime()}`).then(res => {
            this.numberOfFurnitureTypes = res.data
            this.allItems = res.data
            this.AutocompleteItems = this.allItems.map(item => item.name)
            this.rawItems = res.data
            this.filterArray = ['staining', 'untradable']
            this.detailGroups = [
              {
                name: 'Attribute',
                types: ['staining', 'untradable'],
                mapping: ['staining', 'untradable'],
              },
            ]
            this.groups = [
              {
                name: 'Furnishing',
                types: ['Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug'],
                mapping: ['Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug'],
              },
              {
                name: 'Outdoor Furnishing(all)',
                types: ['Outdoor Furnishing'],
                mapping: ['Outdoor Furnishing'],
              },
              {
                name: 'Interior',
                types: ['Interior Wall', 'Flooring', 'Ceiling Light'],
                mapping: ['Interior Wall', 'Flooring', 'Ceiling Light'],
              },
              {
                name: 'Exterior',
                types: ['Roof', 'Exterior Wall', 'Window', 'Door', 'Roof Decoration', 'Exterior Wall Decoration', 'Placard', 'Fence'],
                mapping: ['Roof', 'Exterior Wall', 'Window', 'Door', 'Roof Decoration', 'Exterior Wall Decoration', 'Placard', 'Fence'],
              },
            ]
            for (const item of this.allItems) {
              item.num = ''
            }
            this.loadMoreItems()
          })
          break
      }
    },
    methods: {
      calculateItemCount (group) {
        // ここにグループごとの件数を計算するロジックを書く
        let count = 0
        group.children.forEach(child => {
          count += this.numberOfFurnitureTypes.filter(item => item.type === child.name).length
        })
        return count
      },
      removeFocus () {
        // フォーカスをコンポーネントから外す
        this.$refs.combobox.blur()
      },
      detailToggleCheckbox (item) {
        // 親アイテムで子アイテムを持っている場合、子階層を開く
        if (item.children && item.children.length > 0) {
          const index = this.detailOpened.indexOf(item.name)
          if (index >= 0) {
            this.detailOpened.splice(index, 1)
          } else {
            this.detailOpened.push(item.name)
          }
        } else {
          // 通常のチェックボックスのトグル処理
          const selectedIndex = this.selected.indexOf(item.name)
          if (selectedIndex >= 0) {
            this.selected.splice(selectedIndex, 1)
          } else {
            this.selected.push(item.name)
          }
        }
      },

      toggleCheckbox (item) {
        // 親アイテムで子アイテムを持っている場合、子階層を開く
        if (item.children && item.children.length > 0) {
          const index = this.opened.indexOf(item.name)
          if (index >= 0) {
            this.opened.splice(index, 1)
          } else {
            this.opened.push(item.name)
          }
        } else {
          // 通常のチェックボックスのトグル処理
          const selectedIndex = this.selected.indexOf(item.name)
          if (selectedIndex >= 0) {
            this.selected.splice(selectedIndex, 1)
          } else {
            this.selected.push(item.name)
          }
        }
      },
      clearItems () {
        this.showMasonry = false
        this.masonryContainer++
        this.start = 0 // Items配列を空にする
        this.Items = [] // Items配列を空にする
        this.currentPage = 1 // ページをリセット
        // this.loadMoreItems() // 3. loadMoreItemsの実行
      },
      updateMasonry () {
        this.$nextTick(() => {
          this.$redrawVueMasonry('masonry-container')
        })
      },
      loadMoreItems () {
        this.start = (this.currentPage - 1) * this.perPage
        this.end = this.currentPage * this.perPage

        if (this.start < this.allItems.length) {
          const newItems = JSON.parse(JSON.stringify(this.allItems.slice(this.start, this.end)))
          this.Items = this.Items.concat(newItems)
          this.currentPage++
        }
        this.showMasonry = true
      },

      onIntersect (entries) {
        if (entries[0].isIntersecting && !this.isLoading && this.Items.length < this.allItems.length) {
          this.isLoading = true
          this.loadMoreItems()
          this.isLoading = false
        }
        // this.showMasonry = true
      },

      // アイテムのフィルタリングと更新
      updateAndFilterItems () {
        this.clearItems()

        const beforeLength = this.allItems.length

        // 未加工データを取得
        let filteredItems = this.rawItems

        this.detail_type_selected.forEach(filter => {
          if (filter === '染色' || filter === 'staining') {
            filteredItems = filteredItems.filter(item => String(item.staining) === '1')
          }
          if (filter === 'EX' || filter === 'untradable') {
            filteredItems = filteredItems.filter(item => String(item.ex) === '1')
          }
          if (filter === '課金家具' || filter === 'paid furniture') {
            filteredItems = filteredItems.filter(item => String(item.paid_furniture) === '1')
          }
          if (filter === '店売り') {
            const regex = /Gil/i
            const regex2 = /^(?!.*購入条件).*$/
            filteredItems = filteredItems.filter(item => regex.test(item.shop) && regex2.test(item.shop))
          }
          if (filter === 'カメラ透過防止' || filter === 'camera transmission prevention') {
            filteredItems = filteredItems.filter(item => String(item.camera_transmission_prevention) === '1')
          }
          if (filter === '座り判定' || filter === 'sitting detection') {
            filteredItems = filteredItems.filter(item => String(item.sitting_detection) === '1')
          }
          if (filter === '居眠り判定' || filter === 'dozing detection') {
            filteredItems = filteredItems.filter(item => String(item.dozing_detection) === '1')
          }
          if (filter === '補足' || filter === 'supplement') {
            filteredItems = filteredItems.filter(item => String(item.supplement) !== '')
          }
          if (filter === 'ゲーム内画像無し') {
            filteredItems = filteredItems.filter(furnishing => {
              const isInFurnishingsImg = this.furnishingsImages.some(img => img.text === furnishing.name)
              const isInFurniturePictures = this.furniturePictures.some(pic => pic.text === furnishing.name)
              return !isInFurnishingsImg && !isInFurniturePictures
            })
          }

          if (filter === '影(家具)' || filter === 'shadow furniture') {
            filteredItems = filteredItems.filter(item => String(item.shadow_furniture) === '1')
          }
          if (filter === '影(PC)' || filter === 'shadow pc') {
            filteredItems = filteredItems.filter(item => String(item.shadow_pc) === '1')
          }
          if (filter === '貫通' || filter === 'penetration') {
            filteredItems = filteredItems.filter(item => String(item.penetration) === '1')
          }
          if (filter === '暖色' || filter === 'warm color') {
            filteredItems = filteredItems.filter(item => String(item.warm_color) === '1')
          }
          if (filter === '寒色' || filter === 'cool color') {
            filteredItems = filteredItems.filter(item => String(item.cool_color) === '1')
          }
          if (filter === '中性色' || filter === 'neutral color') {
            filteredItems = filteredItems.filter(item => String(item.neutral_color) === '1')
          }

          // プレビュー種別のフィルタリング条件を収集
          const previewTypeFilters = []
          if (this.detailGroups) {
            this.detailGroups.forEach(group => {
              if (group.name === 'プレビュー種別' || group.name === 'Preview type') {
                group.mapping.forEach((child, index) => {
                  if (this.detail_type_selected.includes(child)) {
                    previewTypeFilters.push(group.types[index])
                  }
                })
              }
            })
          }

          // プレビュー種別に基づくフィルタリング
          if (previewTypeFilters.length > 0) {
            filteredItems = filteredItems.filter(item =>
              previewTypeFilters.some(filter => item.preview_type === filter),
            )
          }
        })

        // searchQueryが空でない場合のみフィルタリングを実行
        if (this.searchQuery) {
          let searchQueries
          const searchQuery = this.searchQuery.trim()

          if (this.$i18n.locale === 'ja') {
            // 日本語の場合、スペースで区切る
            searchQueries = searchQuery.split(/\s+/)
          } else {
            // 日本語以外の場合、searchQueryをそのまま使用
            searchQueries = [searchQuery]
          }

          if (searchQuery !== '') {
            filteredItems = filteredItems.filter(item => {
              return searchQueries.some(query => {
                return item.name.toLowerCase().includes(query.toLowerCase()) ||
                  item.remarks.toLowerCase().includes(query.toLowerCase())
              })
            })
          }
        }

        // 家具種別件数
        this.numberOfFurnitureTypes = filteredItems

        // 家具種別フィルタ
        if (this.selected && this.selected.length > 0) {
          filteredItems = filteredItems.filter(item =>
            this.selected.includes(item.type),
          )
        }

        // 更新されたアイテムリストの適用
        this.allItems = filteredItems
        this.AutocompleteItems = this.allItems.map(item => item.name)

        // 直前の件数が30件以下の場合はコールする
        if (beforeLength <= 30) {
          console.log('取得します')
          this.loadMoreItems()
        }
      },

    },
  }
</script>

<style>
.masonryWrap {
  align: center;
  width: 100%;
}

.item {
  padding: 5px;
  padding-top: 20px;
}

.item-image {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
}

.flex-center {
  display: flex;
  justify-content: center; /* 横方向の中央寄せ */
  align-Items: center;     /* 縦方向の中央寄せ */
  flex-direction: column;  /* 子要素を縦方向に並べる */
  height: 100%;
}

.text-wrap {
  white-space: normal !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.v-btn.text-wrap {
  font-size: 9px; /* フォントサイズを調整 */
}

/* 大サイズの画面（デスクトップ）用のスタイル */
@media (min-width: 960px) {
  .v-btn.text-wrap {
    font-size: 14px; /* 大きい画面ではさらに大きいフォントサイズ */
  }
}

.narrow-container {
  max-width: 180px; /* ここで最大幅を設定 */
  margin: auto; /* 中央揃え */
}

.btn-margin-top {
  margin-top: 15px; /* 10pxのマージンを追加、必要に応じて調整 */
}

.text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px; /* チェックボックスの外側の余白を削除 */
  padding: 0px; /* チェックボックスの内側のパディングを調整 */
}

.text-container {
  display: flex;
  flex-direction: column; /* チェックボックスを縦に並べる */
  align-items: flex-start; /* 左寄せにする */
  gap: 5px; /* チェックボックス間の隙間を設定 */
}

.v-checkbox {
  margin: 0; /* チェックボックスの外側の余白を削除 */
  padding: 4px; /* チェックボックスの内側のパディングを調整 */
}

.v-checkbox .v-label {
  font-size: 12px; /* ラベルのフォントサイズを調整 */
  white-space: normal; /* テキストの折り返しを有効に */
}

/* スマートフォン画面での調整 */
@media (max-width: 600px) {
  .v-checkbox .v-label {
    font-size: 10px; /* スマホではさらに小さいフォントサイズ */
  }
}

.button-spacing {
  margin-left: 10px; /* 左にスペースを追加 */
  /* または margin-right: 10px; で右にスペースを追加 */
}

</style>
